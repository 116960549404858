import React from 'react';
import img2 from "../../images/howitWorks2.svg";
import img3 from "../../images/iconNext.svg";
import img1 from "../../images/slider_new_2.svg"; //old lady
import img4 from "../../images/iconNextLong.svg";
import img5 from "../../images/howitworks3.svg"; //Man With bag
import img6 from "../../images/howitworks4.svg"; //Girl With Machine
import img7 from "../../images/howitworks5.svg"; //Man Folding Clothes
import img8 from "../../images/slider_new_4.svg"; //Girl Delivering Clothes
import Visa from "../../images/visa.svg"; //Visa
import MasterCard from "../../images/mastercard.svg"; //MasterCard

export default function MobileHowITWorks() {
    return(
    <div id="wrapper" className={'mobileView mobileHowitWorks'} style={{color: "#000"}}>
        <div className="container-fluid" style={{padding: "120px 20px 80px 20px"}}>
            <div className="row">   
                {/* First */}
                <div class="row" style={{display: 'flex',alignItems: 'center'}}>
                    <div className="col-md-10">
                        <img src={img1} style={{height: "300px",width: "200px"}} />
                    </div>
                    <div className="col-md-2">
                        <h3 className='titleService'>Bag up<br/>
                            your dirty<br/>
                            laundry<br/>
                        </h3>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-12 arrow'} 
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end'                       
                    }}>
                        <img src={img3} style={{width: "80px", transform: 'rotate(45deg)'}} />
                    </div>
                </div>
            </div>
            {/* First End */}
            <div class="row" style={{display: 'flex',alignItems: 'center'}}>
                <div className="col-md-12">
                    <img src={img2} style={{maxHeight: "300px", width: "100%"}} />
                </div>
            </div>
            <div class="row" style={{display: 'flex',alignItems: 'center'}}>
                    <div class="col-md-12">
                        <h3 className='titleService'>Schedule a free<br/>
                                pick up on<br/>
                                <b>laundrytogo.ca</b><br/>
                        </h3>
                    </div>
            </div>
            <div className="row arrow secondArrow" style={{display: 'flex',alignItems: 'center'}}>
                    <img src={img4} style={{maxHeight: "110px", width: "100px",transform: 'rotate(-45deg)'}} />
            </div>
            <div className="row" style={{display: 'flex',alignItems: 'center'}}>
                <div className="col-md-10">
                    <img src={img5} style={{height: "300px",width: "200px"}} />
                </div>
                <div className="col-md-2">
                    <h3 className='titleService'>We pick up<br/>
                        your dirty<br/>
                        clothes<br/>
                    </h3>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-md-12 arrow'} 
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end'                       
                }}>
                    <img src={img3} style={{width: "80px", transform: 'rotate(45deg)'}} />
                </div>
            </div>
            {/* Second End */}
            <div class="row" style={{display: 'flex',alignItems: 'center'}}>
                <div className="col-md-12">
                    <img src={img6} style={{maxHeight: "300px", width: "100%"}} />
                </div>
            </div>
            <div class="row" style={{display: 'flex',alignItems: 'center',padding: '20px 0 0 20px'}}>
                    <div class="col-md-12">
                        <h3 className='titleService'>We wash <br/>
                        and dry.<br/>
                           Fresh and clean<br/>
                        </h3>
                    </div>
            </div>
            <div className="row arrow thirdArrow" style={{display: 'flex',alignItems: 'center'}}>
                    <img src={img4} style={{maxHeight: "110px", width: "100px",transform: 'rotate(-45deg)'}} />
            </div>
            {/* // Long Arrow */}
            <div class="row" style={{display: 'flex',alignItems: 'center'}}>
                <div className="col-md-12">
                    <img src={img7} style={{maxHeight: "300px", width: "100%"}} />
                </div>
            </div>
            <div class="row" style={{display: 'flex',alignItems: 'center',padding: '20px 0 0 20px'}}>
                    <div class="col-md-12">
                        <h3 className='titleService'>We fold and <br/>sort for you</h3>
                    </div>
            </div>
            <div className={'row'}>
                <div className={'col-md-12 arrow'} 
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end'                       
                }}>
                    <img src={img3} style={{width: "80px", transform: 'rotate(45deg)'}} />
                </div>
            </div>
            {/* Third */}
            <div class="row" style={{display: 'flex',alignItems: 'center',justifyContent: 'flex-end',paddingTop: '20px'}}>
                    <div class="col-md-12">
                        <h3 className='titleService'>
                            We bring back your fresh laundry right to your doorstep on the same day<br/>
                            <br/>
                        </h3>
                    </div>
            </div>
            <div class="row" style={{display: 'flex',alignItems: 'center'}}>
                <div className="col-md-12">
                    <img src={img8} style={{maxHeight: "300px", width: "100%"}} />
                </div>
            </div>
        </div>
    </div>
    )}
        